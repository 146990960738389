.sbscrptnWrpr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 30px;
}

.sbscrptnWrpr .sbsCrtnCard {
    height: 200px;
    background-image: linear-gradient(120deg, #43e97b 0%, #38f9d7 100%);
    color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 19px #84ffbc;
}

.sbscrptnWrpr .sbsCrtnCard p {
    margin-bottom: 2px;
    font-weight: 600;
}

.sbscrptnWrpr .sbsCrtnCard h3 {
    font-size: 30px;
    font-weight: 600;
}

.sbscrptnWrpr .sbsCrtnCard button {
    width: 100%;
    margin-top: 40px;
    height: 50px;
}

.sbscrptnCntnr .cardSelect {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 30px;
}

.pink-btn:disabled {
    pointer-events: none;
}

.sbspntWrpr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 40px 0;
}

.sbspntWrpr .sbsCard {
    border: 1px solid #eaeaea;
    border-radius: 15px;
    padding: 15px;
    padding-top: 30px;
    box-shadow: 0 0 30px #0000000b;
}

.sbspntWrpr .sbsCard h2 {
    font-size: 17px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}

.sbspntWrpr .sbsCard .prcngVlw {
    text-align: center;
}

.sbspntWrpr .sbsCard .prcngVlw h4 {
    color: var(--primColor);
    margin-top: 15px;
    font-weight: 700;
}

.sbspntWrpr .sbsCard .prcngVlw p {
    margin: 0;
    font-size: 14px;
    color: var(--primColor);
    font-weight: 600;
}

.sbspntWrpr .sbsCard ul {
    text-align: center;
    list-style: none;
    padding-left: 0px;
    margin-top: 25px;
    font-size: 14px;
    color: rgb(91, 91, 91);
    min-height: 400px;
}

.sbspntWrpr .sbsCard ul li {
    margin-bottom: 10px;
    text-transform: capitalize;
}

.sbspntWrpr .sbsCrbBtn {
    width: 100%;
    border: 1px solid var(--primColor);
    background: white;
    color: var(--primColor);
    border-radius: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

@media (max-width: 600px) {
    .sbspntWrpr {
        grid-template-columns: 1fr;
        gap: 10px;
    }
}
