.MultiSeller_two .mainHeader {
    height: 100%;
    position: relative;
    top: 0;
    z-index: 1;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
}

.MultiSeller_two a {
    color: var(--primColor);
}
.currentAuc {
    color: var(--primColor) !important;
}
.mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.MultiSeller_two .mainHeader .headRt ul li {
    color: inherit;
}

.MultiSeller_two .mainHeader .headRt ul a {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.MultiSeller_two .mainHeader .headRt ul a.active {
    color: var(--primColor);
}

.mainHeader .headRt ul li button {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
}

.mobHeader .headRt ul li button {
    min-width: initial !important;
    margin: 0;
    width: 40px;
}

.mainHeader .headRt ul li button .material-icons {
    padding-left: 10px;
}

.MultiSeller_two .mainHeader .headRt ul a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: inherit;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.mainHeader .headRt ul a.active:after {
    width: 100%;
    left: 0;
}

.mainHeader .headRt ul a:hover:after {
    width: 100%;
    left: 0;
}

.mainHeader .headRt ul li:not(:last-child) {
    margin-right: 30px;
}

.nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
}

.MultiSeller_two .mainHeader .nav-standard-top-area-search-inner {
    background: #f5f5f5;
    margin-left: 20px;
    width: 600px;
    padding-right: 45px;
    border: 1px solid #9b9b9b;
    border-radius: 30px;
}

.MultiSeller_two .mainHeader .nav-standard-top-area-search-inner > div {
    display: flex;
}

.MultiSeller_two .mainHeader .nav-standard-top-area-search-inner.disabledZip input:first-child {
    margin-right: 0;
    border-right: none;
}

.MultiSeller_two .nav-standard-top-area-search-input:first-child {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 15px;
    background: #fff;
    border-radius: 25px 0 0 25px;
    border-right: 1px solid #9b9b9b;
}

.MultiSeller_two .zip-Search .nav-standard-top-area-search-input:first-child,
.MultiSeller_two .nav-standard-top-area-search-inner.zip-Search select {
    height: 35px;
    border-right: 1px solid #ffffff;
    margin-right: 0;
}

.MultiSeller_two .nav-standard-top-area-search-inner.zip-Search {
    background: #f5f5f5;
    margin-left: 20px;
    margin-right: 15px;
    width: 400px;
    padding-right: 45px;
    border: 1px solid #9b9b9b;
    border-radius: 30px;
}

.MultiSeller_two .zip-Search .inner-Search-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.MultiSeller_two .zip-Search .inner-Search-form.zipDisabled {
    border-radius: inherit;
}

.MultiSeller_two .zip-Search .inner-Search-form.zipDisabled select {
    border-radius: inherit;
}

.MultiSeller_two .zip-Search .nav-standard-top-area-search-sumbit {
    width: 36px;
    height: 36px;
}

.MultiSeller_two .nav-standard-top-area-search-input {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0;
    background: #f5f5f5;
}
.MultiSeller_two .headBottomNav .ho-orange a:hover,
.MultiSeller_two .headBottomNav .ho-orange button:hover {
    /* color: 'orange'; */
    color: var(--primColor);
}
.MultiSeller_two .headBottomNav .ho-orange button {
    background-color: transparent;
    text-transform: capitalize;
    border: transparent;
    color: #2e2e2e;
}
/*
.MultiSeller_two .headBottomNav .ho-orange .primButton button:hover {
    background-color: orange;
}*/

.MultiSeller_two .nav-standard-top-area-search-inner select {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    width: 220px;
    font-size: 15px;
    color: #9b9b9b;
    background: #f5f5f5;
    border-left: 1px solid #9b9b9b;
}

.MultiSeller_two .nav-standard-top-area-search-input::placeholder {
    font-size: 15px;
    color: #9b9b9b;
}

.MultiSeller_two .nav-standard-top-area-search-inner select:focus {
    box-shadow: none;
    outline: 0;
}

.nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.MultiSeller_two .nav-standard-top-area-search-sumbit {
    width: 45px;
    height: 45px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: var(--secColor);
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.MultiSeller_two .mainHeader .headLt .headerLogo {
    width: 180px;
    object-fit: contain;
    height: 70px;
    margin-right: 20px;
}

.headerDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 300px;
    background: #fff;
}

.headerDrawer .mobShopLocation.MuiListItem-root {
    flex-wrap: wrap;
}

.headerDrawer .mobShopLocation.MuiListItem-root p {
    font-size: 14px;
    padding-inline-start: 8px;
    margin: 0;
}

.headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
    width: 100%;
    justify-content: space-between;
}

.headerDrawer .headerDrawerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 100px);
    height: auto;
}

.headerDrawer .headerDrawerNavLinks hr {
    width: 100%;
}

.respNav,
.respNavBtn {
    display: none !important;
}

.respHeaderSearch .MuiPaper-root {
    padding: 15px;
}

.respHeaderSearch .nav-standard-top-area-search-input {
    height: 50px;
}

.respHeaderSearch button {
    height: 100%;
}

.headerAuthBtn {
    background: #f5f5f5;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 10%);
    height: 45px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.headerAuthBtn .material-icons {
    color: var(--primColor);
    font-size: 28px;
    margin-right: 12px;
}

.mainHeader .headRt ul .headerAuthBtn a {
    color: #2e2e2e;
    font-weight: 400;
}

.MultiSeller_two .headBottomNav {
    border-top: 1px solid #dbdbdb;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.MultiSeller_two .headBottomNav ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.MultiSeller_two .headBottomNav li,
.MultiSeller_two .headBottomNav li a {
    font-size: 15px;
    font-weight: 500;
    color: #2e2e2e;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.MultiSeller_two .headBottomNav a:hover,
.MultiSeller_two .headBottomNav a.active {
    color: var(--secColor);
}

.MultiSeller_two .headBottomNav a:after {
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: inherit;
    width: 0;
}

.MultiSeller_two .headBottomNav a:hover:after,
.MultiSeller_two .headBottomNav a.active:after {
    width: 0%;
    left: 0;
    background: var(--secColor);
}

.MultiSeller_two .headBottomNav li > button .MuiButton-label {
    font-size: 15px;
    font-weight: 500;
    color: #2e2e2e;
    text-transform: capitalize;
}

.MultiSeller_two .headBottomNav li:not(:last-child) {
    margin-right: 45px;
}

.MultiSeller_two .contactNav li a:after {
    display: none;
}

.MultiSeller_two .contactNav li:first-child {
    border-right: 1px solid #d0d0d0;
    margin-right: 16px;
    padding-right: 16px;
}

.MultiSeller_two .contactNav .socialIcons a {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: var(--primColor);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.MultiSeller_two .contactNav .socialIcons a i {
    margin: 0 auto;
}

.MultiSeller_two .contactNav .socialIcons a:not(:last-child) {
    margin-right: 10px;
}

.catgDrawer .MuiDrawer-paper {
    width: 350px;
}

.catgDrawer .noCatg {
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.catgDrawer .noCatg img {
    width: 200px;
    height: auto;
    object-fit: contain;
}

.catgDrawer .noCatg h6 {
    font-size: 16px;
    margin: 15px 0 0;
}

.catgDrawer .catgHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
}

.catgDrawer .catgHead h3 {
    font-size: 18px;
    font-weight: 600;
    color: #2e2e2e;
    margin: 0;
    position: relative;
}

.catgDrawer .catgHead h3::after {
    position: absolute;
    content: '';
    width: 70px;
    height: 3px;
    bottom: -10px;
    left: 0;
    border-radius: 5px;
    background: var(--primColor);
}

.catgDrawer .catgHead button {
    min-width: auto;
    position: absolute;
    background: var(--primColor);
    top: 20px;
    right: 0;
    width: 35px;
    height: 25px;
    color: #fff;
    border-radius: 25px 0 0 25px;
}

.catgDrawer .catgLinks {
    margin: 0;
    margin-top: 20px;
}

.catgDrawer .catgLinks .homeCatgInner {
    padding: 15px 20px;
}

.MultiSeller_two .sprtCntnr {
    padding: 8px 0px;
    border-top: 1px solid rgb(194, 194, 194);
    border-bottom: 1px solid rgb(194, 194, 194);
    background: rgb(235, 235, 235);
}

.MultiSeller_two .sprtCntnr h5 {
    font-size: 14px;
    margin-bottom: 0px;
    color: rgb(61, 61, 61);
    padding-left: 20px;
}

.MultiSeller_two .sprtCntnr hr {
    margin-top: 5px;
    margin-bottom: 0px;
}

/* Responsive Code */

@media (max-width: 1410px) {
    .MultiSeller_two .headBottomNav li:not(:last-child) {
        font-size: 14px;
        margin-right: 20px;
    }

    .MultiSeller_two .headBottomNav li a,
    .MultiSeller_two .headBottomNav .MuiButton-label {
        font-size: 13px;
    }
}

@media (max-width: 1290px) {
    .MultiSeller_two .headBottomNav li {
        margin-right: 15px;
    }
}

@media (max-width: 1100px) {
    .MultiSeller_two .mainHeader .nav-standard-top-area-search-inner {
        width: auto;
        max-width: 520px;
        min-width: 350px;
    }
    .MultiSeller_two .headBottomNav li:not(:last-child) {
        margin-right: 25px;
    }
}

@media (max-width: 1024px) {
    .mainHeader {
        height: 80px;
    }

    .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 10px;
    }

    header .headLt img {
        max-width: 180px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .deskNav {
        display: none !important;
    }
    .respNav .navRespLinks li .primButton button {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 6px 19px;
    }
    .respNav .navRespLinks li .primButton button .material-icons {
        margin-right: 16px;
    }
    .respNav {
        display: block !important;
    }
    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: var(--headerFontColor) !important;
    }

    .headRt ul {
        list-style: none;
        margin: 0;
    }

    .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);

        /* justify-content: space-between; */
    }

    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: flex-start;
        font-family: var(--FuturaStdMedium);
        flex-wrap: nowrap;
    }

    .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .headRt.respNav .navRespLinks a.active,
    .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .headRt.respNav .naLogoHead {
        padding: 20px 0px 20px 30px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
    }

    .headRt.respNav .naLogoHead img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }
    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }
    .headerSearchForm {
        width: 90%;
    }
    .mainHeader .headLt .headerLogo {
        width: 100%;
        height: 90px;
    }
}

@media (max-width: 992px) {
    .MultiSeller_two .headBottomNav li:not(:last-child) {
        margin-right: 15px;
    }
}

@media (max-width: 990px) {
    .MultiSeller_two .nav-standard-top-area-search-sumbit {
        width: 50px;
        height: 50px;
    }
    .headRt.respNav .naLogoHead {
        padding: 15px 0px 15px 30px;
    }
    .MultiSeller_two .headRt.respNav .naLogoHead img {
        height: 50px;
        object-fit: contain;
        width: 100%;
        max-width: 130px;
    }
}

@media (max-width: 600px) {
    .MultiSeller_two .zip-Search .inner-Search-form {
        flex-direction: column;
    }
    .MultiSeller_two .nav-standard-top-area-search-inner.zip-Search select {
        width: 100%;
    }
    .MultiSeller_two .nav-standard-top-area-search {
        width: 100%;
    }
    .MultiSeller_two .nav-standard-top-area-search-inner.zip-Search {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
        border: none;
        padding-right: 0;
    }
    .MultiSeller_two .mainHeader .headLt .headerLogo {
        width: fit-content;
        height: 60px;
        object-fit: contain;
    }
    .mobHeader .headRt ul li button {
        width: auto;
        margin-left: 0px;
        padding: 0;
    }
    .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 0;
    }

    .MultiSeller_two .nav-standard-top-area-search {
        border: 1px solid #bcbcbc;
        margin-bottom: 15px;
    }

    .MultiSeller_two .nav-standard-top-area-search-input:first-child,
    .respHeaderSearch .nav-standard-top-area-search-input {
        background: whitesmoke;
        border-radius: 0;
        border-right: none;
        border-bottom: 1px solid rgb(188, 188, 188);
    }

    .MultiSeller_two .nav-standard-top-area-search-sumbit {
        top: unset;
        bottom: 0;
        width: 145px;
        border-radius: 0px;
        height: 45px;
    }

    .MultiSeller_two .nav-standard-top-area-search-inner select {
        border-left: 0px;
    }
}

@media (max-width: 425px) {
    .MultiSeller_two .mainHeader .headLt .headerLogo {
        height: 45px;
    }
}
