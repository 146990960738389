.msgPluginWrapper .fltMsgBtn {
    position: fixed;
    bottom: 40px;
    right: 80px;
    width: 70px;
    height: 70px;
    background: var(--primColor);
    color: white;
    box-shadow: 0 8px 22px #0000003d;
    border: none;
    z-index: 9999;
}

.msgPluginDialog.customDialog .MuiPaper-root.MuiDialog-paper {
    max-width: 600px;
    width: 100%;
    height: 100%;
    max-height: 620px;
}

.msgPluginDialog.customDialog .MuiDialogTitle-root {
    background: whitesmoke;
    padding-top: 25px;
    padding-bottom: 25px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 1%,
        rgba(255, 255, 255, 1) 20%,
        rgba(226, 250, 255, 1) 100%
    );
}

.msgPluginWrapper .fltMsgBtn:hover {
    background: var(--primColor);
    color: white;
    filter: brightness(1.2);
}

.messagePopupTitle .usrPrflCrcle {
    width: 50px;
    height: 50px;
    background: var(--primColor);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 100px;
    color: white;
}

.messagePopupTitle .msgrPrlNm h2 {
    font-size: 22px;
}

.messagePopupTitle .msgrPrlNm p {
    font-size: 14px;
    color: gray;
    margin-bottom: 0px;
}

.messagePopupTitle {
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(223, 223, 223);
}

.messagePlgBody .MessageInrBody .msgOtrWrpr {
    margin-bottom: 20px;
}

.messagePlgBody .MessageInrBody .msgContent {
    padding: 10px 25px;
    /* white-space: pre; */
    background: rgb(242 242 242);
    border-radius: 0 29px 29px 29px;
    width: max-content;
    font-size: 14px;
}

.messagePlgBody .MessageInrBody .sntMsgWrpr {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.messagePlgBody .MessageInrBody .sntMsgWrpr .msgContent {
    border-radius: 29px 0 29px 29px;
    background: white;
    border: 1px solid var(--primColor);
    max-width: 88%;
}

.msgPluginDialog.customDialog .MuiDialogContent-root {
    padding: 0px;
    height: calc(100% - 121px);
}

.msgPluginDialog.customDialog .MuiDialogContent-root .messagePlgBody .MessageInrBody {
    padding: 10px 30px;
    height: 100%;
    overflow: auto;
}

.msgPluginDialog.customDialog .messagePlgBody {
    height: calc(100% - 80px);
}

.msgInputBoxWrpr {
    padding: 10px;
}

.msgInputBoxWrpr .msgInputBox {
    background: white;
    border-radius: 100px;
    box-shadow: 0 0 10px #00000026;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}

.msgInputBoxWrpr .msgInputBox .sndButton {
    background: var(--primColor);
    color: white;
}

.msgInputBoxWrpr .msgInputBox textarea {
    width: 100%;
    margin-right: 10px;
    margin-left: 4px;
    border-radius: 100px;
    padding: 10px 20px;
    height: 50px;
    resize: none;
    border: none;
    font-size: 14px;
}
