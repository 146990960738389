.close-img {
    cursor: pointer;
    padding: 5px;
    position: fixed;
    z-index: 99;
    top: 2px;
}

.searchExt {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background: #dbdbdb;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #222;
    margin-right: auto;
    margin-left: 20px;
}

.checkRotate {
    height: 500px;
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
}

.ArtAuction .image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
    padding: 30px 0;
}

.ArtAuction .checkRotate {
    height: 378px;
}

.hoverZoom img {
    height: 500px !important;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100% !important;
}

.fullscreen .hoverZoom img {
    height: 100vh !important;
    -o-object-fit: contain;
    object-fit: contain;
}

.lotNo {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}
.Liquidation .lotNo {
    font-size: 18px !important;
    font-weight: 700 !important;
}
/* MULTISELLER PRODUCT DRAWER */

.multisellerProductDrawer .mpTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
}

.ArtAuction .multisellerProductDrawer .mpTop {
    margin-bottom: 30px;
}

.multisellerProductDrawer .mpTop .plTitle {
    font-size: 24px;
    color: #2e2e2e;
    font-weight: 600;
    margin: 0;
}

.ArtAuction .multisellerProductDrawer .mpTop .plTitle {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 22px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.ArtAuction .multisellerProductDrawer .mpTop .fullDetils {
    font-size: 20px;
    font-weight: 400;
    color: #1a83f9;
    text-decoration: underline;
    cursor: pointer;
}

.multisellerProductDrawer .mpTop .plTitle span:first-child {
    font-size: 15px;
    margin-bottom: 8px;
    color: #747474;
    font-weight: 500;
    display: block;
}

.multisellerProductDrawer .mssDesc h6 {
    font-weight: 600;
    font-size: 16px;
    color: #333;
}

.multisellerProductDrawer .mssDesc p {
    font-weight: 400;
    font-size: 16px;
    color: #333;
}

.multisellerProductDrawer .close-img {
    left: 0;
    position: absolute;
    top: 10px;
}

.ArtAuction .multisellerProductDrawer .close-img,
.Collectibles .multisellerProductDrawer .close-img {
    left: 0;
    position: absolute;
    top: 0px;
}

.multisellerProductDrawer .mssBidItem {
    /* display: flex;
    justify-content: space-between; */
    margin-top: 20px;
}

.prodInfoSlider .pv-con span {
    font-weight: 600 !important;
    display: inline-block;
    padding-right: 5px;
}

.multisellerProductDrawer .mssBidItem .mssBidInfo h5 {
    font-size: 18px;
    font-weight: 400;
    color: #333;
    margin: 0;
}

.itemPurchased {
    width: 100%;
    text-align: center;
    padding: 10px;
    background: #ffe6c2;
    color: #9c6e2d;
    font-size: 18px;
    margin-top: 20px;
}

/* .multisellerProductDrawer .mssBidItem .mssBidInfo h5:first-child {
    margin-bottom: 15px;
} */

.multisellerProductDrawer .mssBidItem .mssBidInfo h5 span {
    font-weight: 600;
    color: #333;
    margin-right: 10px;
    display: inline-block;
}

.liquidationTwo .multisellerProductDrawer .mssBidItem .mssBidInfo h5 span:nth-child(2) {
    font-weight: 400;
}

.multisellerProductDrawer .mssBidItem .mssBidInfo h5:last-child {
    color: #373737;
}

.ArtAuction .multisellerProductDrawer .mssBidItem .mssBidInfo h5:last-child {
    display: grid;
    text-align: left;
}

/* .multisellerProductDrawer .slidView .biddingCnt .primButton,
.multisellerProductDrawer .slidView .biddingCnt .secButton {
    margin-right: 0;
} */

/* .multisellerProductDrawer .slidView .biddingCnt > div:first-child,
.multisellerProductDrawer .slidView .biddingCnt > div:first-child {
    margin-left: auto;
} */

.multisellerProductDrawer .sl-pbids.slidView {
    width: 480px;
}

.multisellerProductDrawer .sl-pbids.slidView.notLogged {
    width: 100%;
}

.multisellerProductDrawer .mssTimer {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
    text-align: center;
    border: 1px dashed #ccc;
    letter-spacing: 1px;
    color: #555;
}

.FoodIndustry .multisellerProductDrawer .mssTimer {
    position: absolute;
    top: 0px;
    width: 82%;
    left: 9%;
    background: #ffffffe3;
    top: 15px;
    border-radius: 5px;
}

.FoodIndustry .multisellerProductDrawer .mssBidItem {
    margin-top: 0px;
}

.ArtAuction .followUp .avatharIntractive {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ArtAuction .followUp .avatharIntractive .secButton {
    margin-left: 12px;
}

.ArtAuction .followUp .avatharIntractive .favoriteCheck label {
    color: #fff;
    line-height: 0;
    margin-top: 0;
}

.ArtAuction .followUp .avatharIntractive .secButton .MuiButton-root {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    color: #ffffff;
    background: #1a83f9;
    border-radius: 100px;
    height: 40px;
    padding: 6px 15px;
    border: 1px solid #1a83f9;
}

.ArtAuction .followUp .avatharIntractive .primButton .MuiButton-root,
.ArtAuction .followUp .avatharInfo .primButton .MuiButton-root {
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    text-transform: capitalize;
    color: #ffffff;
    background: #0a0a0a;
    border-radius: 100px;
    padding: 6px 15px;
    height: 40px;
    border: 1px solid #0a0a0a;
}

.ArtAuction .followUp .avatharInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 41%;
}

.ArtAuction .followUp .avatharInfo .userCrd .userPic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ededed;
    /* border-radius: 50%;*/
}

.ArtAuction .followUp .avatharInfo .userCrd .userPic {
    width: 100%;
    height: 100%;
    max-width: 150px;
    overflow: hidden;
    /* border-radius: 100px;*/
    margin-right: 9px;
}

.ArtAuction .followUp .avatharInfo .userCrd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ArtAuction .followUp .avatharInfo .userCrd .userIdenty {
    width: 100%;
    margin-top: 10px;
}

.ArtAuction .followUp .userIdenty .usrposi {
    font-size: 16px;
    font-weight: 400;
    -webkit-line-clamp: 1;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #808080;
    margin: 0;
    white-space: nowrap;
}

.ArtAuction .followUp .userIdenty .userTit {
    font-size: 20px;
    font-weight: 400;
    -webkit-line-clamp: 1;
    width: 150px;
    color: #0a0a0a;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ArtAuction .followUp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 66px;
    margin-bottom: 51px;
}

.ArtAuction .similarArtSection .followUp {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 56px;
}

.ArtAuction .similarArtSection .followUp .avatharIntractive {
    width: 100%;
    justify-content: flex-start;
}

.ArtAuction .similarArtSection .followUp .avatharInfo {
    padding: 25px 15px;
    margin-top: 28px;
    width: 70%;
}

.multisellerProductDrawer .sl-pbids.slidView button {
    width: 100%;
    margin: initial;
}

.ArtAuction .multisellerProductDrawer .sl-pbids.slidView button {
    background-color: #070707;
    height: 50px;
}

.ArtAuction .ArtpaymentOption .types span {
    padding-right: 5px;
}

.ArtAuction .ArtpaymentOption .types {
    font-size: 20px;
    font-weight: 400;
    color: #373737;
    margin-left: 7px;
}

.ArtAuction .ArtpaymentOption .Tit {
    font-size: 20px;
    font-weight: 400;
    color: #373737;
}

.ArtAuction .ArtpaymentOption {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.FoodIndustry .multisellerProductDrawer .sl-pbids.slidView button {
    border-radius: 8px;
}

.Marine .multisellerProductDrawer .sl-pbids.slidView .customInput {
    margin-bottom: 1px;
}

.multisellerProductDrawer .sl-pbids.slidView .customInput {
    margin-bottom: 15px;
}

.multisellerProductDrawer .sl-pbids.slidView .bidDet:first-child {
    margin-bottom: 15px;
}

.multisellerProductDrawer .slidView .biddingCnt .primButton {
    margin-right: 0;
}

.multisellerProductDrawer .slidView .biddingCnt > div {
    width: 100%;
}

.multisellerProductDrawer .sl-pbids.slidView .secButton button,
.sl-pbids.slidView .secButton button {
    width: 100%;
    height: 40px;
}

.multisellerProductDrawer .sl-pbids .secButton {
    margin: 10px 0;
}

.multisellerProductDrawer .mssBidHistory {
    color: var(--primColor);
    text-transform: initial;
    margin: 0;
    font-weight: 600;
}

.ArtAuction .multisellerProductDrawer .mssBidHistory {
    margin-bottom: 10px;
}

.multisellerProductDrawer .mssBidMisc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.ArtAuction .multisellerProductDrawer .mssBidMisc {
    align-items: flex-end;
    margin-bottom: 20px;
    width: 100%;
    max-width: 60%;
}

.multisellerProductDrawer .mssBidIntDet .mssBidMisc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.multisellerProductDrawer .mssBidIntDet .mssBidMisc .mssBidInfo:not(:last-child) {
    margin-right: 25px;
}

.multisellerProductDrawer .mssBidHistory .material-icons {
    margin-right: 10px;
}

.multisellerProductDrawer .mssBidHistory .MuiButton-label {
    font-weight: 600;
}

.ArtAuction .multisellerProductDrawer .mssBidHistory .MuiButton-label,
.Collectibles .multisellerProductDrawer .mssBidHistory .MuiButton-label {
    font-weight: 500;
}

.ArtAuction .multisellerProductDrawer .mssBidHistory .MuiButton-label {
    font-size: 18px;
    font-weight: 400;
    color: #1a83f9;
}

.multisellerProductDrawer .MuiAccordion-root .MuiAccordionSummary-root {
    margin-bottom: 0;
}

.multisellerProductDrawer .MuiAccordion-root .accTitle {
    margin-bottom: 0;
}

.multisellerProductDrawer .multiSellerTimerView h6 {
    font-size: 22px;
    font-weight: 500;
}

.multisellerProductDrawer .multiSellerTimerView h6 span {
    color: #070707;
}

.multisellerProductDrawer .timerWrapper .timeLabel::before {
    left: 5%;
}

.multisellerProductDrawer .timerWrapper .timeLabel::after {
    right: 5%;
}

.ArtAuction .multisellerProductDrawer .timerWrapper .timeLabel::before {
    left: 0;
}

.ArtAuction .multisellerProductDrawer .timerWrapper .timeLabel::after {
    right: 0;
}

.FoodIndustry .productPageAct,
.HeavyEquipment .productPageAct {
    display: inline-flex !important;
}

.LiquidationPallet .productPageAct {
    display: inline-flex !important;
}

.productViewDrawer .bidStatus {
    padding-top: 60px;
}

.productViewDrawer .MuiDrawer-paper .productStatus {
    position: absolute;
    top: 10px;
    width: 100%;
    left: 0;
    max-width: 670px;
}

.productViewDrawer .MuiDrawer-paper .productStatus.hwaiPosiChange {
    position: absolute;
    top: auto;
    bottom: -35px;
    margin-bottom: 18px;
    left: 0;
    padding-left: 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.FoodIndustry .productViewDrawer .MuiDrawer-paper .productStatus {
    position: absolute;
    top: 10px;
    width: 100%;
    left: 0;
    padding-left: 0px;
    top: 64px;
}

.productViewDrawer .productStatus h4 {
    text-align: center;
    height: auto;
    width: 100%;
    display: flex;
    padding: 10px;
    width: auto;
    position: relative;
    z-index: 100;
}

.rightDrawer .loadingCnt {
    display: block;
}

.sliderLoader {
    padding: 15px;
}

.sliderLoader .productViewSkeleton .pvsImage,
.sliderLoader .productViewSkeleton .pvsDetails {
    width: 100%;
}

.sliderLoader .productViewSkeleton .skeletonWrapper {
    padding: 0;
    margin: 10px;
    box-shadow: none;
}

.pvsBottom > header {
    background-color: #f5f5f5;
    box-shadow: none;
    border-radius: 45px;
    margin: 45px 0 15px 0;
}

.pvsBottom .MuiTabs-root .MuiTab-root {
    height: 45px;
    min-height: initial;
    border-radius: 45px;
    opacity: 1;
}

.pvsBottom .MuiTab-root .MuiTab-wrapper {
    flex-direction: row;
}

.pvsBottom .MuiTab-root .MuiTab-wrapper .material-icons {
    padding-right: 8px;
}

.pvsBottom .MuiTabs-root .MuiTab-root.Mui-selected {
    background: var(--primColor);
}

.pvsBottom .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: #fff;
}

.pvsBottom .MuiTabs-root .MuiTabs-indicator {
    display: none;
}

.pvsBottom .MuiTabs-root .MuiTabs-flexContainer {
    border-bottom: none;
    width: 100%;
    max-width: initial;
    display: flex !important;
}

.productPageAct {
    width: 100%;
    color: var(--secColor);
    border: 1px solid var(--secColor);
    margin-bottom: 15px;
}

.FoodIndustry .productPageAct {
    border: 1px solid var(--primColor);
    margin-bottom: 25px;
    color: var(--primColor);
}

.LiquidationPallet .productPageAct {
    border: 1px solid var(--primColor);
    margin-bottom: 25px;
    color: var(--primColor);
}

.FoodIndustry .msSliderInner,
.HeavyEquipment .msSliderInner {
    padding-top: 50px !important;
}

.FoodIndustry .msSliderInner .biddingCnt .primButton {
    margin-right: 10px;
}

.FoodIndustry .msSliderInner .biddingCnt .primButton button {
    border-radius: 4px;
    margin-left: 10px;
}

/* .prodInfoSlider {
    margin-top: -30px;
} */

.prodInfoSlider .image-gallery-left-nav,
.prodInfoSlider .image-gallery-right-nav {
    width: 35px;
    height: 35px;
    background: #fff;
    padding: 0;
    border: 1px solid #ddd;
    -webkit-filter: none;
    filter: none;
    border-radius: 50%;
}

.prodInfoSlider .image-gallery-left-nav:hover .image-gallery-svg,
.prodInfoSlider .image-gallery-right-nav:hover .image-gallery-svg {
    transform: none;
    color: #737373;
}

.prodInfoSlider .image-gallery-left-nav:hover,
.prodInfoSlider .image-gallery-right-nav:hover {
    background: #ddd;
}

.prodInfoSlider .image-gallery-left-nav .image-gallery-svg,
.prodInfoSlider .image-gallery-right-nav .image-gallery-svg {
    width: inherit;
    height: inherit;
    color: #c5c5c5;
}

.howBidLink {
    color: var(--primColor);
    cursor: pointer;
}

.rotateImage {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
    position: absolute !important;
    bottom: 0px;
    right: 40px;
    color: #5c5c5c !important;
    background: #cccccca1;
    z-index: 10;
    height: 40px;
    width: 40px !important;
}

.rotateImage .material-icons {
    font-size: 32px;
}

.image-gallery-fullscreen-button {
    padding: 0 !important;
    height: 40px;
    width: 40px;
}

.image-gallery-fullscreen-button .image-gallery-svg {
    color: #5c5c5c !important;
}

.image-gallery-fullscreen-button .image-gallery-icon {
    -webkit-filter: none !important;
    filter: none !important;
}

.cpyToClipDiv {
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    background: #4fa7ac;
    justify-content: space-around;
    border-radius: 100px;
}

.ArtAuction .goBackBTn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ArtAuction .goBackBTn u {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
}

.ArtAuction .MuiPaper-root .cpyToClipDiv {
    margin-left: 5px;
    margin-bottom: 5px;
}

.ArtAuction .MuiPaper-root .react-share__ShareButton {
    padding: 5px !important;
}

.cpyToClipDiv .material-icons {
    font-size: 18px;
    color: white;
}

/*ART AUCTOIN*/

.ArtAuction .multisellerProductDrawer .mssTimer,
.Collectibles .multisellerProductDrawer .mssTimer {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
    text-align: center;
    border: none;
    letter-spacing: 1px;
    color: #555;
}

.ArtAuction .multisellerProductDrawer .mssTimer .material-icons-outlined,
.Collectibles .multisellerProductDrawer .mssTimer .material-icons-outlined {
    font-size: 30px;
    color: var(--secColor);
}

.ArtAuction .multisellerProductDrawer .topNmCntnr,
.Collectibles .multisellerProductDrawer .topNmCntnr {
    padding-top: 10px;
}

.ArtAuction .multisellerProductDrawer .topNmCntnr {
    padding-right: 20px;
}

.ArtAuction .multisellerProductDrawer .lotNo,
.Collectibles .multisellerProductDrawer .lotNo {
    color: gray;
}

.ArtAuction .multisellerProductDrawer .lotNo {
    font-size: 18px;
    color: #747474;
    font-weight: 400;
}

.ArtAuction .multisellerProductDrawer .image-gallery,
.Collectibles .multisellerProductDrawer .image-gallery {
    margin-bottom: 20px;
}

.ArtAuction .multisellerProductDrawer .image-gallery {
    margin-bottom: 10px;
}

.ArtAuction .multisellerProductDrawer h5.estValue span,
.ArtAuction .multisellerProductDrawer .mssBidInfo h5 span {
    font-size: 20px;
    font-weight: 400;
    color: #808080;
}

.ArtAuction .multisellerProductDrawer h5.estValue,
.ArtAuction .multisellerProductDrawer .mssBidInfo h5,
.Collectibles .multisellerProductDrawer .mssBidInfo h5 {
    font-size: 16px;
}

.ArtAuction .multisellerProductDrawer h5.estValue span,
.ArtAuction .multisellerProductDrawer .mssBidInfo h5 span,
.Collectibles .multisellerProductDrawer .mssBidInfo h5 span {
    font-weight: normal;
    margin-right: 3px;
}

.ArtAuction .multisellerProductDrawer h5.estValue,
.ArtAuction .multisellerProductDrawer .mssBidInfo h5 {
    font-size: 16px;
}

.ArtAuction .multisellerProductDrawer h5.estValue span,
.ArtAuction .multisellerProductDrawer .mssBidInfo h5 span {
    font-size: 16px;
}

.ArtAuction .multisellerProductDrawer h5.estValue span,
.ArtAuction .multisellerProductDrawer .mssBidInfo h5 span {
    margin-bottom: 10px;
}

.ArtAuction .multisellerProductDrawer .mssBidItem .mssBidInfo h5 .crncyValue,
.Collectibles .multisellerProductDrawer .mssBidItem .mssBidInfo h5 .crncyValue {
    color: var(--primColor);
    font-weight: bold;
    font-size: 20px;
    margin-left: 40px;
}

.ArtAuction .multisellerProductDrawer .mssBidItem .mssBidInfo h5 .crncyValue {
    margin-left: 0;
    font-size: 34px;
    font-weight: 500;
    color: #0a0a0a;
    line-height: 69px;
    margin-bottom: 0;
}

/* .ArtAuction .multisellerProductDrawer .mssBidItem .mssBidInfo h5 .crncyValue.makemycurrency {
    display: none;
} */

/* .ArtAuction .sl-pbids.slidView {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
} */

/* .biddingCnt .customInput .MuiFormControl-root .MuiOutlinedInput-inputMarginDense {
    padding-top: 18.5px;
    padding-bottom: 18.5px;
} */

/* .biddingCnt .MuiFormLabel-root.MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 20px) scale(1);
} */

/* .biddingCnt .MuiFormLabel-root.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
} */
.tooling {
    color: #000;
    background-color: #e1e1e1;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ArtAuction .MuiInputLabel-outlined {
    margin: 3px 0;
}

.ArtAuction .multisellerProductDrawer .sl-pbids.slidView .bidDet,
.Collectibles .multisellerProductDrawer .sl-pbids.slidView .bidDet {
    width: 100%;
}

.ArtAuction .multisellerProductDrawer .sl-pbids.slidView .bidDet .bidderInfo {
    font-size: 18px;
    font-weight: 400;
    display: flex;
    color: #808080;
}

.ArtAuction .multisellerProductDrawer .sl-pbids .secButton,
.Collectibles .multisellerProductDrawer .sl-pbids .secButton {
    margin: 0;
}

.ArtAuction .multisellerProductDrawer .sl-pbids .secButton button,
.Collectibles .multisellerProductDrawer .sl-pbids .secButton button {
    border: 1px solid var(--secColor);
    color: var(--secColor);
}

.ArtAuction .multisellerProductDrawer .sl-pbids .secButton button:hover,
.Collectibles .multisellerProductDrawer .sl-pbids .secButton button:hover {
    border: 1px solid var(--secColor);
    background: var(--secColor);
    color: white;
}

.ArtAuction .multisellerProductDrawer .sl-pbids.slidView .customInput,
.Collectibles .multisellerProductDrawer .sl-pbids.slidView .customInput {
    margin-right: 10px;
}

.ArtAuction
    .multisellerProductDrawer
    .sl-pbids.slidView
    .customInput
    .MuiOutlinedInput-inputAdornedEnd {
    padding-top: 15px;
    padding-bottom: 15px;
}

.ArtAuction .multisellerProductDrawer .sl-pbids.slidView .customInput .MuiFormControl-root,
.Collectibles .multisellerProductDrawer .sl-pbids.slidView .customInput .MuiFormControl-root {
    border-radius: 4px;
}

.ArtAuction .MuiAccordionSummary-root,
.Collectibles .MuiAccordionSummary-root {
    background: #f7f7f7;
}

.ArtAuction .MuiAccordion-root,
.Collectibles .MuiAccordion-root {
    border: none;
    width: 100%;
}

.ArtAuction .MuiAccordion-root {
    margin-bottom: 15px;
}

.ArtAuction .multisellerProductDrawer .sl-pbids.slidView,
.Collectibles .multisellerProductDrawer .sl-pbids.slidView {
    width: 100%;
}

.Collectibles .multisellerProductDrawer .artisName {
    display: none;
}

/* SLIDE POPUP */

.spCnt {
    display: grid;
    grid-template-columns: 400px auto;
    gap: 20px;
}

.spCnt .image-gallery {
    width: 100%;
}

.Auctioneer .slidePopup .plTitle {
    font-size: 18px;
    background: #898989;
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    margin-bottom: 15px;
    border-radius: 0 10px 10px 0;
}

.Auctioneer .bottomDrawer .MuiPaper-root.MuiDrawer-paper {
    border-radius: 10px;
}

.Auctioneer .image-gallery-slide.center {
    background: whitesmoke;
    border-radius: 10px;
}

.slidePopup .spMisc {
    display: flex;
    justify-content: space-between;
}

.slidePopup .lotNo {
    text-align: right;
}

.slidePopup .lotNo span {
    margin-right: 8px;
    font-size: 13px;
    font-weight: 600;
    color: #242424;
}

.slidePopup .pvMiscLabel {
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 10px;
}

.slidePopup .pvShare button:not(:last-child) {
    margin-right: 8px;
}

.slidePopup .mssBidInfo h5,
.slidePopup .mssBidInfo .timerCnt {
    font-size: 13px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 10px;
    display: block;
}

.slidePopup .mssBidInfo h5 span,
.slidePopup .mssBidInfo .timerCnt span {
    width: 150px;
    color: #242424;
    display: inline-block;
}

.slidePopup .mssBidInfo .timerCnt {
    color: #d31616;
}

.slidePopup .mssDesc h6 {
    font-size: 14px;
    font-weight: 600;
    color: var(--secColor);
    margin-bottom: 5px;
}

.slidePopup .mssDesc div * {
    font-size: 13px;
    font-weight: 600;
    color: #242424;
}

.slidePopup .image-gallery-thumbnail,
.slidePopup .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 50px;
    width: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    border: none !important;
}

.slidePopup .image-gallery-thumbnail:hover {
    opacity: 0.75;
}

.more-details {
    border: none;
    background-color: transparent;
    color: #2e70b0;
}

.more-details:hover {
    text-decoration: underline;
}

.slidePopup .image-gallery-thumbnail:hover,
.slidePopup .image-gallery-thumbnail:focus,
.slidePopup .image-gallery-thumbnail.active {
    border: none;
    opacity: 1;
}

.slidePopup .image-gallery-slide-wrapper,
.slidePopup .image-gallery-slides,
.slidePopup .image-gallery-slide,
.slidePopup .image-gallery-slide img {
    height: 300px;
}

.slidePopup .spLt .favoriteCheck {
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100px;
    width: 45px;
}

.slidePopup .closeBtn {
    position: absolute;
    top: 0px;
    right: 5px;
    min-width: unset;
    border-radius: 100px;
    width: 40px;
    height: 40px;
}

.slidePopup .sliderLoader .loadingCnt {
    display: block;
}

.slidePopup .sliderLoader .loadingCnt .productViewSkeleton > div {
    display: grid;
    grid-template-columns: 400px auto;
    gap: 20px;
    justify-content: normal !important;
}

.bottomDrawer .productStatus {
    padding: 20px 0;
    height: 30px;
    border-radius: 30px;
    margin: 0;
    /* padding-left: 30px; */
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 118px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 8px;
}

.bottomDrawer h5.mssBidHistory {
    color: var(--secColor);
    font-size: 13px;
    font-weight: 500;
}

.bottomDrawer .pvsBottom > header {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    margin: 45px 0 15px 0;
}

.bottomDrawer .pvsBottom .MuiTabs-root .MuiTab-root {
    background: whitesmoke;
    border-radius: 0px;
}

.bottomDrawer .pvsBottom .MuiTabs-root .MuiTab-root:not(:last-child) {
    border-right: 3px solid white;
}

.bottomDrawer .pvsBottom .MuiTabs-root .MuiTab-root.Mui-selected {
    background: #9ea2a2;
    color: white;
}

.bottomDrawer .pvsBottom .tabBody {
    border: 1px solid #eaeaea;
    padding: 10px;
    height: 100%;
}

.bottomDrawer .bynwBtn.primButton button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: var(--secColor);
    border: 1px solid var(--secColor);
}

.bottomDrawer .byorBdBtn.primButton button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.bottomDrawer .bynwBtn.primButton button.Mui-disabled {
    color: rgb(185, 185, 185);
}

.bottomDrawer .sl-pbids.slidView {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 21px;
    column-gap: 21px;
    max-width: 830px;
    width: 100%;
}

.shwMrBtnWrp {
    margin-bottom: 20px;
}

.pdtDscrpnVlue ul,
.pdtDscrpnVlue ol,
.pdtDscrpnVlue dl {
    padding-left: 20px;
}

.multisellerProductDrawer .shwMrBtn {
    white-space: pre;
    margin-left: 30px;
}

.FoodIndustry .multisellerProductDrawer .mssBidItem .mssBidInfo h5 {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    margin: 0;
    line-height: 25px;
}

/*MULTI AUCTION PRODUCT VIEW PAGE CSS*/
.multiAuctionProductView .dscrptnCntns {
    margin-bottom: 20px;
}

.multiAuctionProductView .colorPrimary {
    color: var(--primColor);
}

.multiAuctionProductView .crntPriceWrapper {
    text-align: right;
}

.multiAuctionProductView .crntPriceWrapper h5 {
    font-size: 30px;
    font-weight: bold;
    color: var(--primColor);
}

.MultiAuction .multiAuctionProductView .crntPriceWrapper h5 {
    font-weight: 600;
}

.multiAuctionProductView .minBdInfo {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 15px 0;
}

.productViewStandalone .multiAuctionProductView .productInfo.minBdInfo h5 {
    margin-bottom: 0;
}

.multiAuctionProductView .rtInfoContainer {
    max-width: 350px;
}

.multiAuctionProductView .slidViewBtnLabel {
    width: 100%;
}

.productViewStandalone .multiAuctionProductView .productInfo h5 {
    font-size: 15px;
}

.productViewStandalone .multiAuctionProductView .productInfo h5 span {
    width: 135px;
}

.imgGlrWrpr {
    position: relative;
    display: block;
}

.imgGlrWrpr .soldWrapper {
    background: #00000096;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 59px;
    color: #ffffffa3;
    font-weight: bold;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    display: -webkit-flex;
}

.liquidationTwo .imgGlrWrpr .soldWrapper {
    height: 100%;
    /* min-height: 434px;
    max-height: 434px;
    display: grid;
    display: -ms-grid;
    place-content: center; */
}

.imgGlrWrpr .soldWrapper p {
    margin: 0;
    transform: rotate(324deg);
}

.dscrpnCtnrArt img {
    width: 100%;
}

.dscrpnCtnrArt ol,
.dscrpnCtnrArt ul,
.dscrpnCtnrArt dl {
    padding-left: 30px;
}

.msSliderInner .vdoPlyrWrapper > div {
    width: 100% !important;
}

.LiquidationPallet .msSliderInner .image-gallery-fullscreen-button {
    width: calc(100% - 120px);
    height: calc(100% - 40px);
    bottom: unset;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
}

.prodInfoSlider .additionalDesc h5 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.prodInfoSlider .additionalDesc p {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 700;
    color: #4a4a4a;
}

.prodInfoSlider .additionalDesc p span {
    font-weight: 400;
    color: #333;
}

.prodInfoSlider.zoomOnHover .image-gallery-slides {
    display: none;
}

.text-Yellow {
    color: #ffcc00;
}

.prodInfoSlider .galleryPrice.rtlrPrice {
    color: #333;
    margin-top: 15px;
}

@media (max-width: 880px) {
    .ArtAuction .followUp .avatharIntractive {
        width: unset;
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .ArtAuction .rightDrawer .MuiPaper-root.MuiDrawer-paper > .msSliderInner {
        padding: 30px 20px !important;
    }

    .ArtAuction .multisellerProductDrawer .lotNo {
        font-size: 15px;
    }

    .ArtAuction .multisellerProductDrawer .mpTop .plTitle {
        font-size: 20px;
    }

    .ArtAuction .followUp {
        margin-top: 10px;
    }

    .ArtAuction .followUp .avatharInfo {
        width: 100%;
    }

    .ArtAuction .followUp .avatharInfo .userCrd {
        flex-wrap: wrap;
    }

    .ArtAuction .followUp .avatharIntractive {
        width: 100%;
        margin-top: 15px;
    }

    .ArtAuction .followUp .avatharIntractive .primButton .MuiButton-root,
    .ArtAuction .followUp .avatharInfo .primButton .MuiButton-root,
    .ArtAuction .followUp .avatharIntractive .secButton .MuiButton-root {
        font-size: 13px;
    }

    .ArtAuction .followUp .userIdenty .userTit {
        font-size: 18px;
        margin-top: 10px;
    }

    .productViewDrawer .MuiDrawer-paper .productStatus.hwaiPosiChange h4 {
        transform: translateX(0px);
    }

    .productViewDrawer .MuiDrawer-paper .productStatus.hwaiPosiChange {
        position: absolute;
        top: auto;
        bottom: -50px;
        margin-bottom: 18px;
    }

    .multisellerProductDrawer .mssBidMisc.multisellertwo {
        align-items: flex-start;
    }

    .prodInfoSlider .shippingCnt {
        flex-wrap: wrap;
    }

    .checkRotate {
        height: 500px;
        width: 100%;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .shwMrBtnWrp {
        flex-wrap: wrap;
    }

    .multisellerProductDrawer .mssBidItem .mssBidInfo h5:first-child,
    .multisellerProductDrawer .mssBidItem .mssBidInfo h5:last-child {
        font-size: 16px;
    }

    .multisellerProductDrawer .mssBidIntDet .mssBidMisc .mssBidInfo {
        margin-right: auto;
    }

    .FoodIndustry .multisellerProductDrawer .mssBidItem .mssBidInfo h5:first-child,
    .FoodIndustry .multisellerProductDrawer .mssBidItem .mssBidInfo h5:last-child {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .FoodIndustry .multisellerProductDrawer .mpTop .plTitle {
        font-size: 19px;
    }

    .FoodIndustry .multisellerProductDrawer .mssDesc h6 {
        font-size: 14px;
    }

    .FoodIndustry .multisellerProductDrawer .mssDesc p {
        font-size: 14px;
    }

    .multisellerProductDrawer .shwMrBtn {
        font-size: 14px;
        margin-left: auto;
    }

    .howBidLink {
        font-size: 13px;
        margin-top: 10px;
    }

    .spCnt {
        grid-template-columns: 100%;
    }

    .slidePopup .spMisc {
        flex-wrap: wrap;
    }

    .slidePopup .lotNo {
        text-align: left;
    }

    .slidePopup .pvShare {
        display: flex;
        align-items: center;
    }

    .slidePopup .pvMiscLabel {
        margin-bottom: 0px;
        margin-right: 10px;
    }

    .slidePopup.bottomDrawer .sl-pbids.slidView {
        grid-template-columns: 100% !important;
    }

    .slidePopup.bottomDrawer h5.mssBidHistory {
        white-space: pre;
    }

    .slidePopup .mssDesc div ul,
    .slidePopup .mssDesc div ol,
    .slidePopup .mssDesc div dl {
        padding-left: 20px;
    }
}

.WithStyles\(ForwardRef\(Accordion\)\)-root-25:not(:last-child) {
    width: 100% !important;
}

@media (max-width: 1025px) {
    .Collectibles .WithStyles\(ForwardRef\(Accordion\)\)-root-13:not(:last-child) {
        width: 100%;
    }

    .WithStyles\(ForwardRef\(Accordion\)\)-root-31:not(:last-child) {
        width: 100% !important;
    }

    .Collectibles .MuiAccordionSummary-root {
        background-color: #fff !important;
    }
}

@media (max-width: 600px) {
    .multisellerProductDrawer .mssBidMisc .mssBidHistory {
        min-width: max-content;
    }

    .ArtAuction .multisellerProductDrawer .mssBidItem .mssBidInfo h5 .crncyValue {
        font-size: 24px;
        line-height: 24px;
    }
}

@media (max-width: 475px) {
    .multisellerProductDrawer .mssBidMisc {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .multisellerProductDrawer .mssBidItem {
        margin-top: 0;
    }

    .Marine .multisellerProductDrawer .mssBidMisc {
        margin-top: 15px;
    }

    .Marine .checkRotate {
        height: 215px;
    }

    .multisellerProductDrawer .mssBidMisc .mssBidHistory {
        width: 100%;
    }

    .multisellerProductDrawer .mpTop {
        display: block;
    }

    .multisellerProductDrawer .mpTop .topNmCntnr {
        margin-bottom: 15px;
    }

    .sl-pbids.slidView {
        width: 100% !important;
    }

    .multisellerProductDrawer .mssBidHistory {
        color: var(--primColor);
        text-transform: initial;
        margin: auto;
        font-weight: 600;
        margin-top: 10px;
        display: flex;
        margin-bottom: 10px;
    }

    .multisellerProductDrawer .mssBidItem .mssBidInfo h5:first-child {
        margin-bottom: 15px;
    }

    .multisellerProductDrawer .mssBidMisc,
    .multisellerProductDrawer .mssBidIntDet .mssBidMisc {
        margin-bottom: 0px;
    }

    .multisellerProductDrawer .mssBidItem .mssBidInfo h5:first-child,
    .multisellerProductDrawer .mssBidItem .mssBidInfo h5:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .Collectibles .multisellerProductDrawer .mssBidItem .mssBidInfo h5 .makemycurrency {
        display: none;
    }

    .multisellerProductDrawer .mssBidItem {
        flex-direction: column-reverse;
    }

    .multisellerProductDrawer .mssBidItem b {
        text-align: center;
        margin-bottom: 15px;
    }

    .ArtAuction .multisellerProductDrawer .mpTop,
    .Collectibles .multisellerProductDrawer .mpTop {
        flex-direction: column;
    }
}

.slidePopup .image-gallery-content.fullscreen .image-gallery-slide-wrapper,
.slidePopup .image-gallery-content.fullscreen .image-gallery-slides,
.slidePopup .image-gallery-content.fullscreen .image-gallery-slide,
.slidePopup .image-gallery-content.fullscreen .image-gallery-slide img {
    height: 100%;
    min-height: 93vh;
}

.hide {
    width: 100%;
    line-height: 1.2em;
    height: 3.6em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.productViewStandalone .productStatus {
    width: 100%;
    max-width: unset;
    padding: 0;
}

.vehicleProduct .pvsRight .vehicleDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px;
    background: whitesmoke;
    margin-bottom: 30px;
}

.vehicleProduct .pvsRight .vehicleDetails ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0px;
}

.vehicleProduct .pvsRight .vehicleDetails ul li {
    display: flex;
    align-items: center;
}

.vehicleProduct .pvsRight .vehicleDetails ul li:not(:last-child) {
    margin-bottom: 3px;
}

.vehicleProduct .pvsRight .vehicleDetails ul li label {
    margin: 0;
    color: gray;
    font-weight: 500;
    background: #ececec;
    padding: 10px;
    min-width: 40%;
    margin-right: 10px;
}

.vehicleProduct .pvsRight .vehicleDetails ul li span {
    font-weight: 500;
    text-transform: capitalize;
}

.vehicleProduct .bdngWrapper {
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 25px;
}

.vehicleProduct .bdngWrapper .dtInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.vehicleProduct .bdngWrapper .dtInfo h5 {
    background: whitesmoke;
    padding: 10px 15px;
    border-radius: 10px;
    text-align: center;
    font-size: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vehicleProduct .bdngWrapper .dtInfo h5 span {
    padding-right: 15px;
    color: gray;
}

.vehicleProduct .bdngWrapper .dtInfo h5 span.material-icons {
    padding-right: 5px;
    font-size: 20px;
}

.productViewStandalone .vehicleProduct .productInfo h5 {
    height: 40px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.vehicleProduct .lotChip {
    padding: 6px 15px;
    font-size: 14px;
    background: whitesmoke;
    border-radius: 100px;
    margin-top: 10px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.productViewStandalone .vehicleProduct .mpTop {
    align-items: flex-end;
}

.descriptionContainer.textHide {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.descriptionContainer ul,
.descriptionContainer ol,
.descriptionContainer dl {
    padding-left: 30px;
}

.dscrptnWthScrl {
    max-height: 150px;
    overflow: auto;
    font-size: 13px;
    font-weight: 500;
}

.vehicleProduct .phtSwpr .pswp-thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 7px;
}

.vehicleProduct .phtSwpr .pswp-thumbnails .pswp-thumbnail img {
    width: 100%;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
}

.vehicleProduct .phtSwpr .pswp__img {
    -o-object-fit: contain;
    object-fit: contain;
}

.liquidationTwo .prodInfoSlider .pdtStsInfo ul li p {
    font-weight: 600;
    white-space: unset;
    width: unset;
}

.vehicleProduct p.cntBdOwn {
    background: #ffdcdc;
    padding: 15px 20px;
    margin: 0;
    border-radius: 10px;
    color: red;
    text-align: center;
    font-weight: 500;
    width: 100%;
}

@media (max-width: 700px) {
    .vehicleProduct .pvsRight .vehicleDetails {
        grid-template-columns: 1fr;
    }

    .ArtAuction .msSliderInner .mssBidMisc,
    .ArtAuction .msSliderInner .mssBidMisc .mssBidInfo {
        width: 100%;
    }

    .ArtAuction .msSliderInner .mssBidMisc .mssBidInfo h5 {
        flex-wrap: wrap;
    }

    .ArtAuction .msSliderInner .mssBidMisc .mssBidInfo h5 span:first-child {
        width: 100%;
    }

    .ArtAuction .msSliderInner .mssBidMisc .mssBidInfo h5 .crncyValue {
        margin-left: 0px !important;
    }
}

@media (max-width: 600px) {
    .Auctioneer .bottomDrawer .MuiPaper-root.MuiDrawer-paper {
        max-width: 95vw;
    }

    .pvsRight.multiAuctionProductView {
        flex-direction: column;
    }

    .MultiAuction .multiAuctionProductView .rtInfoContainer {
        max-width: unset;
        width: 100%;
    }

    .ArtAuction .bidderInfo {
        font-size: 18px;
        font-weight: 400;
        color: #808080;
    }

    .MultiAuction .productViewStandalone .sl-pbids.slidView {
        margin-left: 0px !important;
    }

    .MultiAuction .multiAuctionProductView .crntPriceWrapper {
        text-align: left;
    }

    .MultiAuction .multiAuctionProductView .minBdInfo {
        justify-content: flex-start;
    }

    .ArtAuction .orTxtWrpr {
        padding-bottom: 10px;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 12px !important;
    }

    .Collectibles .orTxtWrpr {
        padding-bottom: 10px;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 12px !important;
    }
}

@media (max-width: 767px) {
    .prodInfoSlider {
        margin-top: -20px;
    }
}

@media (max-width: 600px) {
    .Collectibles .checkRotate {
        height: 330px !important;
    }
}

@media (max-width: 500px) {
    .myWrapping .pdtDscrpnVlue,
    .myWrapping .bullet-list-wrapper {
        max-width: 390px;
        width: 100%;
    }

    .liquidationTwo .shwMrBtnWrp {
        flex-wrap: wrap !important;
    }
}
@media (max-width: 425px) {
    .MultiSeller_two .cpyToClipDiv {
        margin-left: 0px;
    }
    .pvMiscActions button:not(:first-child) {
        margin-left: 6px !important;
    }
    .MultiSeller_two .pvMiscActions {
        flex-wrap: wrap;
    }
}
@media (max-width: 368px) {
    .ArtAuction .ArtpaymentOption .Tit,
    .ArtAuction .ArtpaymentOption .types {
        font-size: 17px;
    }
}

@media (max-width: 360px) {
    .ArtAuction .followUp .avatharIntractive {
        display: block;
    }

    .ArtAuction .followUp .avatharIntractive .secButton {
        margin-left: 0px;
        margin-top: 5px;
    }
}
