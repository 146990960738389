.dbmhead {
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    color: #000;
    line-height: 1;
}

.dbmhead img {
    display: inline-block;
    vertical-align: top;
}

.dbmlinks {
    margin: 0 5px !important;
    padding: 0 !important;
    background: transparent;
}
.dbmlinks a {
    color: #000;
    text-decoration: none;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    padding: 20px;
    border-top: 4px solid transparent;
}

.dbmlinks a.active,
.dbmlinks a:hover,
.dbmlinks a:focus {
    background: #fff;
    color: var(--primColor);
    text-decoration: none;
    border-top: 4px solid var(--primColor);
}

.dbmlinks {
    overflow: inherit !important;
}

.dashboard-wrapper {
    padding: 50px 15px;
    z-index: 1;
    position: relative;
}

.ArtAuction .dashboard-wrapper.savedSearches .secButton button {
    border: 1px solid #000000;
    color: #000000;
}

.ArtAuction .dashboard-wrapper.savedSearches .secButton button:hover {
    color: #fff;
    background-color: #000000;
}

.mp-head {
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    color: #000;
    text-transform: uppercase;
    margin: 15px 0;
}

.mp-head1 {
    font-weight: 500;
    font-size: 22px;
    text-align: left;
    color: #000;
}

.profile-form-wrap form .primButton {
    max-width: 200px;
    width: max-content;
    margin-bottom: 15px;
}

.dashboardMenu > header,
.dashboardMenu .MuiToolbar-root {
    background: #ececec;
}

.liquidationDashTabs .MuiTabs-flexContainer {
    border: none;
}

.liquidationDashTabs .MuiTabs-scroller {
    text-align: center;
}

.liquidationDashTabs .MuiTab-root.Mui-selected {
    background: var(--primColor);
}

.liquidationDashTabs .MuiTab-root {
    background: #ececec;
}

.liquidationDashTabs .MuiTab-root .MuiTab-wrapper {
    color: #323749;
}

.liquidationDashTabs .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: #fff;
}

.table thead th {
    background: #343a40;
}

/* HeavyEquipment css */
.HeavyEquipment .notificationPara {
    font-size: 16px;
    font-weight: 400;
    color: #0e131f;
    margin: 35px auto;
    text-align: center;
}

.Liquidation .table-responsive .pdtNmClmn {
    width: 250px;
}

@media (max-width: 991px) {
    .dbmlinks a {
        padding: 5px 10px;
        width: 100%;
    }

    .dashboardMenu {
        display: none;
    }
}

@media (max-width: 767px) {
    .mobile-table .hidden-mobile {
        display: none !important;
    }

    .mobile-table,
    .mobile-table tr,
    .mobile-table td,
    .mobile-table tbody {
        display: block !important;
        border: 0;
        width: 100%;
    }

    .Liquidation .table-responsive .mobile-table td.pdtNmClmn,
    .mobile-table td {
        width: 100%;
        padding: 0 0 5px 100px;
    }

    .mobile-table tr {
        position: relative;
        padding: 10px 0 10px 90px;
        border-bottom: 1px solid #dbdbdb;
        min-height: 120px;
    }

    .mobile-table tbody td.ml-data {
        position: absolute;
        left: 0;
        padding-left: 0;
        width: 90px;
        text-align: center;
    }

    .mobile-table tbody td.ml-data.top-90 {
        top: 90px;
    }

    .mobile-table tbody td.ml-data img {
        max-height: 75px;
        object-fit: contain;
    }

    .MuiContainer-root.dashboard-wrapper {
        padding: 25px 12px;
    }

    .MuiContainer-root.dashboard-wrapper .MuiContainer-root {
        padding: 0;
    }
    .dashboard-wrapper .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 12px;
        width: 100%;
    }
    .dashboard-wrapper .db-menu-links {
        text-align: center;
    }

    .dashboard-wrapper .db-menu-links a {
        text-align: center;
        display: inline-block;
        width: 49%;
        padding: 14px 12px;
        font-size: 14px;
        vertical-align: top;
    }
    .dbmlinks.MuiListItem-gutters {
        padding: 0 12px !important;
        min-height: 40px;
    }
    .dbmlinks a.active:after {
        bottom: -10px;
    }
}

@media (max-width: 600px) {
    .Liquidation .table-responsive .mobile-table td.pdtNmClmn,
    .mobile-table td {
        padding: 0 0 5px 50px;
    }
    .mobile-table td .hide-web {
        display: block;
    }
}

@media (max-width: 475px) {
    .mp-head {
        font-size: 16px;
    }
}

.preferences-form-wrapper .primButton button {
    width: 200px;
    margin: 0 auto;
    display: block;
}
