.pagination {
    justify-content: flex-end;
    margin: 30px auto 10px;
}

.page-link {
    position: relative;
    display: block;
    padding: 12px 15px;
    margin: 0 0 0 10px;
    line-height: 1.25;
    color: #5d646c;
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    text-align: center;
}

.page-item.active .page-link,
.page-item:hover .page-link,
.page-item:focus .page-link,
.page-item:active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #5d646c;
    border-color: #5d646c;
}
