.column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.button-container {
    display: flex;
    justify-content: end;
}

.customTextArea .MuiTextField-root .MuiInputBase-root,
.customTextArea .MuiTextField-root .MuiInputBase-root textarea {
    min-height: 150px !important;
}

.form-control.textArea {
    height: 150px;
}

.label-header {
    font-size: 20px;
    font-weight: 600;
}

.label-description {
    padding: 0px 15px;
    font-size: 20px;
    font-weight: 600;
}
.feedMessage {
    width: 250px;
}
.feedbackmsg {
    word-break: break-all;
}
.blue {
    color: #00a1e6;
}
.feedTabs {
    max-width: 1280px;
    width: 100%;
}

.ml-10 {
    margin-left: 10px;
}

.none {
    display: none;
}
@media (max-width: 768px) {
    .profile-form-wrapper.Extra .profile-form-wrap .reg-step1 {
        flex-wrap: wrap;
        justify-content: center;
    }
}
