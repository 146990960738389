.thisflex {
    max-width: 1000px;
    margin: auto;
}
.liquidationTwo .pdtNmClmn {
    white-space: nowrap;
}
.lctnDialogWrapper {
    max-height: 700px;
}
.amountPop b {
    white-space: nowrap;
}
.dashboard-tabs .table-responsive button {
    white-space: nowrap;
}
input[type='text'],
input[type='number'],
select:focus,
textarea {
    font-size: 16px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
    }
    select,
    textarea,
    input {
        font-size: 16px;
    }
}
